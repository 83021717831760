import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { h1 } from '../styles/typography';
import { media } from '../styles';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { Helmet } from 'react-helmet';
import Rte from '../components/Rte';
import Row from '../components/Row';

const StyledPage = styled.div`
    background: ${props => props.theme.colors.greyLight};
`;

const StyledRow = styled(Row)`
    position: relative;
    overflow: initial;
`;

const StyledTitle = styled.h1`
    ${h1};
    position: sticky;
    top: ${props => props.theme.sizes.headerHeight}px;
    padding-left: ${props => props.theme.spacing.s40};
    padding-top: ${props => props.theme.spacing.s70};
    font-weight: bold;
    color: ${props => props.theme.colors.red};
`;

const StyledLeft = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${sizeAsBase(27)};

    ${media.mobile`
        position: relative;
        top: auto;
        left: auto;
        padding-left: ${sizeAsBase(2)};
        padding-top: ${props => props.theme.spacing.s20};
        margin-bottom: ${props => props.theme.spacing.s80};
    `}
`;
const StyledRight = styled.div`
    position: relative;
    margin-left: ${sizeAsBase(27)};
    margin-right: ${sizeAsBase(-3)};
    background: ${props => props.theme.colors.white};
    min-height: 500px;

    ${media.large`
        margin-right: ${sizeAsMax(-3)};
        margin-left: ${sizeAsMax(27)};
    `}

    ${media.mobile`
        margin-left: ${sizeAsBase(-3)};

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: ${sizeAsBase(3)};
            margin-left: -1px;
            width: 1px;
            background: ${props => props.theme.colors.grey};
        }

        &:after {
            left: auto;
            margin-left: auto;
            margin-right: -1px;
            right: ${sizeAsBase(3)};
        }
    `}
`;

const StyledRte = styled(Rte)`
    padding: ${props => props.theme.spacing.s80} ${sizeAsBase(5)};

    ${media.large`
        padding-left: ${sizeAsMax(5)};
        padding-right: ${sizeAsMax(5)};
    `};
`;

const Simple = ({ data }) => {
    const { seo_title, seo_description, title, content } = data.page.data;

    return (
        <StyledPage>
            <Helmet>
                <title>{seo_title}</title>
                {seo_description && <meta name="description" content={seo_description} />}
            </Helmet>
            <StyledRow>
                <StyledLeft>
                    <StyledTitle>{title.text}</StyledTitle>
                </StyledLeft>
                <StyledRight>
                    <StyledRte dangerouslySetInnerHTML={{ __html: content.html }} />
                </StyledRight>
            </StyledRow>
        </StyledPage>
    );
};

export default Simple;

export const pageQuery = graphql`
    query SimplePageQuery($lang: String!, $uid: String!) {
        page: prismicPageSimple(lang: { eq: $lang }, uid: { eq: $uid }) {
            uid
            data {
                seo_title
                seo_description
                title {
                    text
                }
                content {
                    html
                }
            }
        }
    }
`;
